import React from "react";
import { useRef } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import { SwiperSlide } from "swiper/react";
import Carousel from "../Swiper/Carousel";
import "swiper/scss";
import "swiper/scss/navigation";
import { useEffect } from "react";
import { useState } from "react";
import Equipe from "../data/Equipe";
import MonConcept from "../data/MonConcept";
import Head from "../../layout/Head";

function Concept() {
  const [index, setIndex] = useState(0);
  const [view, setView] = useState(0);
  const to = useRef();
  function focus() {
    to.current.scrollIntoView({ behavior: "smooth" });
  }
  const data = [
    {
      url: require("../../asset/felicienAubar.jpg"),
    },
    {
      url: require("../../asset/fun2.JPG"),
    },
  ];
  function whichData(index) {
    switch (index) {
      case 0:
        setView(0);
        break;
      case 1:
        setView(1);
        break;

      default:
    }
  }
  function which() {
    if (view === 0) {
      return <Equipe />;
    } else if (view === 1) {
      return <MonConcept />;
    } else {
      return <Equipe />;
    }
  }
  const myView = which();

  useEffect(() => {
    whichData(index);
  }, [index]);

  return (
    <main className="container_concept">
      <Head
        title="Notre concept"
        content="Félicien et Yoann, barmans de formation, vous accueillent avec bonne humeur en salle et vous font découvrir tout ce que la Seine-et-Marne a de bon et de gourmand. Milca, infirmière de métier, s'occupera de toute la partie événementielle et culturelle, avec l'énergie qui la caractérise."
      />
      <div className="concept_opac">
        <h1 className="concept_title">notre concept</h1>
        <div className="opac"></div>
        <div className="scroll" onClick={() => focus()}>
          <MdKeyboardArrowDown className="emoji" />
        </div>
      </div>
      <section className="container_us" ref={to}>
        <div className="container_us_flex">
          <h2>Découvrez notre concept</h2>
          <section className="us">
            <article className="container_carousel">
              <Carousel value={setIndex}>
                {data?.map((content, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <div className="us_img">
                        <img src={`${content.url}`} alt="" />
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Carousel>
            </article>
            {myView}
          </section>
        </div>
      </section>
    </main>
  );
}

export default Concept;
