import React, { useCallback, useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";

function Timeline() {
  const refTime = useRef([]);
  const [current, setCurrent] = useState("1787");
  let mySection = document.querySelectorAll(".test");
  const getAllRef = useCallback(() => {
    const mySection = document.querySelectorAll(".test");
    if (mySection.length > 0) {
      if (refTime.current.length === 21) {
        return;
      }

      return (refTime.current = Array.from(mySection));
    }
  }, []);

  useEffect(() => {
    if (mySection) {
      getAllRef();
    }
  }, [mySection, getAllRef]);

  return (
    <ul className="timeline">
      <li
        className={`${current === "1787" ? "timeline_item" : ""}`}
        onClick={() => {
          setCurrent("1787");
          refTime.current[0].scrollIntoView({
            behavior: "smooth",
          });
        }}
      >
        1787
      </li>
      <li
        className={`${current === "1810" ? "timeline_item" : ""}`}
        onClick={() => {
          setCurrent("1810");
          refTime.current[1].scrollIntoView({
            behavior: "smooth",
          });
        }}
      >
        1810
      </li>
      <li
        className={`${current === "1887" ? "timeline_item" : ""}`}
        onClick={() => {
          setCurrent("1887");

          refTime.current[2].scrollIntoView({
            behavior: "smooth",
          });
        }}
      >
        1887
      </li>
      <li
        className={`${current === "1891" ? "timeline_item" : ""}`}
        onClick={() => {
          setCurrent("1891");

          refTime.current[3].scrollIntoView({
            behavior: "smooth",
          });
        }}
      >
        1891
      </li>
      <li
        className={`${current === "1893" ? "timeline_item" : ""}`}
        onClick={() => {
          setCurrent("1893");

          refTime.current[4].scrollIntoView({
            behavior: "smooth",
          });
        }}
      >
        1893
      </li>
      <li
        className={`${current === "1894" ? "timeline_item" : ""}`}
        onClick={() => {
          setCurrent("1894");

          refTime.current[5].scrollIntoView({
            behavior: "smooth",
          });
        }}
      >
        1894
      </li>
      <li
        className={`${current === "1904" ? "timeline_item" : ""}`}
        onClick={() => {
          setCurrent("1904");

          refTime.current[7].scrollIntoView({
            behavior: "smooth",
          });
        }}
      >
        1904
      </li>
      <li
        className={`${current === "1908" ? "timeline_item" : ""}`}
        onClick={() => {
          setCurrent("1908");

          refTime.current[6].scrollIntoView({
            behavior: "smooth",
          });
        }}
      >
        1908
      </li>
      <li
        className={`${current === "1912" ? "timeline_item" : ""}`}
        onClick={() => {
          setCurrent("1912");

          refTime.current[11].scrollIntoView({
            behavior: "smooth",
          });
        }}
      >
        1912
      </li>
      <li
        className={`${current === "1944" ? "timeline_item" : ""}`}
        onClick={() => {
          setCurrent("1944");

          refTime.current[14].scrollIntoView({
            behavior: "smooth",
          });
        }}
      >
        1944
      </li>
      <li
        className={`${current === "1952" ? "timeline_item" : ""}`}
        onClick={() => {
          setCurrent("1952");

          refTime.current[15].scrollIntoView({
            behavior: "smooth",
          });
        }}
      >
        1952
      </li>
      <li
        className={`${current === "1957" ? "timeline_item" : ""}`}
        onClick={() => {
          setCurrent("1957");

          refTime.current[16].scrollIntoView({
            behavior: "smooth",
          });
        }}
      >
        1957
      </li>
      <li
        className={`${current === "1965" ? "timeline_item" : ""}`}
        onClick={() => {
          setCurrent("1965");

          refTime.current[17].scrollIntoView({
            behavior: "smooth",
          });
        }}
      >
        1965
      </li>
      <li
        className={`${current === "1990" ? "timeline_item" : ""}`}
        onClick={() => {
          setCurrent("1990");

          refTime.current[18].scrollIntoView({
            behavior: "smooth",
          });
        }}
      >
        1990
      </li>
      <li
        className={`${current === "Aujourd'hui" ? "timeline_item" : ""}`}
        onClick={() => {
          setCurrent("Aujourd'hui");

          refTime.current[19].scrollIntoView({
            behavior: "smooth",
          });
        }}
      >
        Aujourd'hui
      </li>
    </ul>
  );
}

export default Timeline;
