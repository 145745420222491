import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Drink from "../data/Drink";
import Food from "../data/Food";
import Head from "../../layout/Head";

function Menu() {
  const [view, setView] = useState("");
  useEffect(() => {
    setView("two");
  }, []);
  function which() {
    if (view === "one") {
      return <Food />;
    } else if (view === "two") {
      return <Drink />;
    } else {
      return <Food />;
    }
  }
  function showMenu(cat) {
    switch (cat) {
      case "Catégorie 1":
        setView("one");
        break;
      case "Catégorie 2":
        setView("two");
        break;
      default:
        setView("one");
        break;
    }
  }
  const myView = which();
  return (
    <main className="container_menu ">
      <Head
        title="Menu gastronomique et conviviale de notre région"
        content="Produits naturels issus de circuit court mettant en avant la richesse gastronomique et conviviale de notre région"
      />
      <section className="section_menu">
        <div className="container_nav">
          <ul className="menu_nav">
            <li
              onClick={() => showMenu("Catégorie 1")}
              className={`${view === "one" ? "activeBut" : null}`}
            >
              On mange quoi ?{" "}
            </li>
            <li
              onClick={() => showMenu("Catégorie 2")}
              className={`${view === "two" ? "activeBut" : null}`}
            >
              On boit quoi ?
            </li>
          </ul>
        </div>
        {myView}
      </section>
    </main>
  );
}

export default Menu;
