import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { getData } from "../../utils/config";
function Food() {
  const [food, setFood] = useState([]);
  const [cheese, setCheese] = useState([]);
  const [tartine, setTartine] = useState([]);
  useEffect(() => {
    getData("foods")
      .then((res) => {
        setFood(res.data.data);
      })
      .catch((err) => {
      });
    getData("cheeses")
      .then((res) => {
        setCheese(res.data.data);
      })
      .catch((err) => {
      });
    getData("tartines")
      .then((res) => {
        setTartine(res.data.data);
      })
      .catch((err) => {
      });
  }, []);
  return (
    <article className="menu_content">
      {food.length > 0 && (
        <>
          <div className="container_name">
            <span>{food[0].attributes.category}</span>
          </div>
          <ul className="content_nav">
            {food[0]?.attributes.item.map((product, index) => {
              return (
                <li className="item_content" key={index}>
                  {product.produit}
                </li>
              );
            })}
          </ul>
        </>
      )}
      {cheese.length > 0 && (
        <>
          <div className="container_name">
            <span>{cheese[0].attributes.category}</span>
          </div>
          <ul className="content_nav">
            {cheese[0]?.attributes.item.map((product, index) => {
              return (
                <li className="item_content" key={index}>
                  {product.produit}
                </li>
              );
            })}
          </ul>
        </>
      )}
      {tartine.length > 0 &&
        tartine.map((tart, index) => {
          return (
            <div key={index}>
              <div className="container_name">
                <span>{tart.attributes.category}</span>
                <p>(à voir sur place)</p>
              </div>
              {tart.attributes.item.length === 0 ? null : (
                <ul className="content_nav">
                  {tart.attributes.item.map((produit) => {
                    return (
                      <li className="item_content" key={produit.id}>
                        {produit.produit}
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          );
        })}
    </article>
  );
}

export default Food;
