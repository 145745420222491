import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { AiOutlineFileProtect } from "react-icons/ai";
import { AiFillCaretRight } from "react-icons/ai";
import { AiOutlineSecurityScan } from "react-icons/ai";

import { useNavigate } from "react-router-dom";
function SideInfos() {
  const [IsOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      setIsOpen(false);
    }, 5000);
  }, [IsOpen]);
  return (
    <>
      {IsOpen ? (
        <div
          className={`sideInfos ${IsOpen ? "" : "sideInfosHide"} sideInfosOPen`}
        >
          <AiOutlineFileProtect
            className="emoji"
            onClick={() => {
              navigate("/mention-legales");
              setIsOpen(false);
            }}
          />
          <AiOutlineSecurityScan
            className="emoji"
            onClick={() => {
              navigate("/rgpd");
              setIsOpen(false);
            }}
          />
        </div>
      ) : (
        <div
          className="sideInfos sideInfosClose"
          onClick={() => setIsOpen(!IsOpen)}
        >
          <AiFillCaretRight className="emoji" />
        </div>
      )}
    </>
  );
}

export default SideInfos;
