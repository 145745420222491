import React from "react";
import Head from "../../layout/Head";
function Home() {
  return (
    <main className="container_home">
      <Head
        title="LaCoopBoissy - Événements culturels - Boissy-le-Châtel"
        content="Vous voulez découvrir tout ce que la Seine-et-Marne a de bon et de gourmand, animé par des événements culturels et faire revivre un lieu emblématique du village de Boissy-le-Châtel ? Vous êtes au bon endroit."
      />
      <div className="bg_opac"></div>
      <div className="container_title">
        <h1 className="title">LACOOP</h1>
        <h1 className="title">LACOOP</h1>
      </div>
      <section className="home_horaire">
        <p>2 rue du centre - Boissy-le-Châtel - 77169 </p>
        <div className="horaire_title">
          <h3> Horaire </h3>
        </div>
        <div className="horaire_close">
          <h3>Ouvert :</h3>
          <p>Du Mardi au Dimanche de 16h à 00h00</p>
          {/* <p> de 11h à 21h00</p> */}
        </div>
        <div className="horaire_close">
          <h3>Fermé :</h3>
          <p>Lundi</p>
        </div>
      </section>
    </main>
  );
}

export default Home;
