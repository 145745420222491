import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import Head from "../../layout/Head";
import VideoBar from "../../asset/MontageFINAL.mp4";
function Galerie() {
  const [image, setImage] = useState([]);
  useEffect(() => {
    axios
      .get("https://www.strapiadmin.lacoopboissy.fr/api/galeries?populate=*")
      .then((res) => {
        setImage(res.data.data);
      })
      .catch((err) => err);
  }, []);
  return (
    <main className="container_event">
      <Head
        title="Galerie"
        content="Retrouvez tous nos souvenirs passés en votre compagnie au bar LaCoopBoissy."
      />
      <section className="menu_content">
        <div className="container__video-bar">
          <video autoPlay muted controls>
            <source src={VideoBar} type="video/mp4" />
            Your browser does not support the video tag.
          </video>{" "}
        </div>
        <ul className="image-gallery">
          {image
            ?.sort((a, b) => {
              // Triez les éléments en fonction de la date la plus récente
              const dateA = new Date(a.attributes.createdAt);
              const dateB = new Date(b.attributes.createdAt);
              return dateB - dateA;
            })
            .map((galery, index) => {
              return (
                <li key={index}>
                  <img
                    src={`https://www.strapiadmin.lacoopboissy.fr/${galery.attributes.lacoop.data.attributes.url}`}
                    alt=""
                  />
                </li>
              );
            })}
        </ul>
      </section>
    </main>
  );
}

export default Galerie;
