import React from "react";

function Legal() {
  return (
    <main className="container_mentions">
      <section className="mentions_section">
        <h1 className="title_mentions_legale">MENTIONS LEGALES :</h1>
        <div>
          <p>
            Conformément aux dispositions des articles 6-III et 19 de la Loi n°
            2004-575 du 21 juin 2004 pour la Confiance dans l'économie
            numérique, dite L.C.E.N., nous portons à la connaissance des
            utilisateurs et visiteurs du site :
            <a
              className="link_mention_legale"
              href="https://www.lacoopboissy.fr"
              target="_blank"
              rel="noreferrer"
            >
              www.lacoopboissy.fr
            </a>{" "}
            les informations suivantes :
          </p>
        </div>
      </section>
      {/* One */}
      <section className="mentions_section">
        <h2 className="title_mentions_legale">ÉDITEUR</h2>
        <div>
          <p>
            Le site{" "}
            <a
              className="link_mention_legale"
              href="http://www.lacoopboissy.fr"
              target="_blank"
              rel="noreferrer"
            >
              www.lacoopboissy.fr
            </a>{" "}
            est la propriété exclusive de SARL LaCoopBoissy, qui l'édite.
          </p>
          <p>
            LaCoopBoissy SARL au capital de 3000€ <br />
            Tél: 06 58 08 04 09
          </p>
          <p>
            2 rue du centre 77169 Boissy-le-Châtel
            <br />
            Immatriculée au Registre du Commerce et des Sociétés de 918559279
            sous le numéro 91855927900016
          </p>
          <p>
            Numéro TVA intracommunautaire : FR96918559279
            <br />
            Adresse de courrier électronique : lacoopboissy@gmail.com
            <br />
            Directeur de la publication : Tyron-William Chanu
            <br />
            Contactez le responsable de la publication :
            frenchwebdeveloper@gmail.com
          </p>
        </div>
      </section>
      {/* TWO */}
      <section className="mentions_section">
        <h2 className="title_mentions_legale">HÉBERGEMENT</h2>
        <p>
          Le site est hébergé par OVHCloud 2 rue Kellermann BP 80157 59053 CEDEX
          1 ROUBAIX
        </p>
        <h3 className="title_mentions_legale">CREDITS :</h3>
        <p>
          {" "}
          les mentions légales ont étés générées par{" "}
          <a
            className="link_mention_legale"
            href="https://www.generer-mentions-legales.com"
          >
            mentions légales
          </a>
          <br />
          Horaires de la{" "}
          <a
            className="link_mention_legale"
            href="http://www.patinoire.biz/p+patinoire-de-lyon---charlemagne+113.html"
          >
            Patinoire Lyon
          </a>
        </p>
      </section>
      {/* Three */}
      <section className="mentions_section">
        <h3 className="title_mentions_legale">
          DESCRIPTION DES SERVICES FOURNIS
        </h3>
        <p>
          Le site{" "}
          <a
            className="link_mention_legale"
            href="http://www.lacoopboissy.fr"
            target="_blank"
            rel="noreferrer"
          >
            www.lacoopboissy.fr
          </a>{" "}
          a pour objet de fournir une information concernant l'ensemble des
          activités de la société. Le proprietaire du site s'efforce de fournir
          sur le site{" "}
          <a
            className="link_mention_legale"
            href="http://www.lacoopboissy.fr"
            target="_blank"
            rel="noreferrer"
          >
            www.lacoopboissy.fr
          </a>{" "}
          des informations aussi précises que possible. Toutefois, il ne pourra
          être tenue responsable des omissions, des inexactitudes et des
          carences dans la mise à jour, qu'elles soient de son fait ou du fait
          des tiers partenaires qui lui fournissent ces informations. Tous les
          informations proposées sur le site{" "}
          <a
            className="link_mention_legale"
            href="http://www.lacoopboissy.fr"
            target="_blank"
            rel="noreferrer"
          >
            www.lacoopboissy.fr
          </a>{" "}
          sont données à titre indicatif, sont non exhaustives, et sont
          susceptibles d'évoluer. Elles sont données sous réserve de
          modifications ayant été apportées depuis leur mise en ligne.
        </p>
      </section>
      {/* Four */}
      <section className="mentions_section">
        <h3 className="title_mentions_legale">
          PROPRIÉTÉ INTELLECTUELLE ET CONTREFAÇONS
        </h3>
        <p>
          Le proprietaire du site est propriétaire des droits de propriété
          intellectuelle ou détient les droits d'usage sur tous les éléments
          accessibles sur le site, notamment les textes, images, graphismes,
          logo, icônes, sons, logiciels… Toute reproduction, représentation,
          modification, publication, adaptation totale ou partielle des éléments
          du site, quel que soit le moyen ou le procédé utilisé, est interdite,
          sauf autorisation écrite préalable à l'email : lacoopboissy@gmail.com.
          Toute exploitation non autorisée du site ou de l'un quelconque de ces
          éléments qu'il contient sera considérée comme constitutive d'une
          contrefaçon et poursuivie conformément aux dispositions des articles
          L.335-2 et suivants du Code de Propriété Intellectuelle.
        </p>
      </section>
      {/* Five */}
      <section className="mentions_section">
        <h3 className="title_mentions_legale">LIENS HYPERTEXTES ET COOKIES</h3>
        <p>
          Le site{" "}
          <a
            className="link_mention_legale"
            href="http://www.lacoopboissy.fr"
            target="_blank"
            rel="noreferrer"
          >
            www.lacoopboissy.fr
          </a>{" "}
          contient un certain nombre de liens hypertextes vers d'autres sites
          (partenaires, informations …) mis en place avec l'autorisation de le
          proprietaire du site . Cependant, le proprietaire du site n'a pas la
          possibilité de vérifier le contenu des sites ainsi visités et décline
          donc toute responsabilité de ce fait quand aux risques éventuels de
          contenus illicites. L'utilisateur est informé que lors de ses visites
          sur le site{" "}
          <a
            className="link_mention_legale"
            href="http://www.lacoopboissy.fr"
            target="_blank"
            rel="noreferrer"
          >
            www.lacoopboissy.fr
          </a>
          , un ou des cookies sont susceptible de s'installer automatiquement
          sur son ordinateur. Un cookie est un fichier de petite taille, qui ne
          permet pas l'identification de l'utilisateur, mais qui enregistre des
          informations relatives à la navigation d'un ordinateur sur un site.
          Les données ainsi obtenues visent à faciliter la navigation ultérieure
          sur le site, et ont également vocation à permettre diverses mesures de
          fréquentation. Le paramétrage du logiciel de navigation permet
          d'informer de la présence de cookie et éventuellement, de refuser de
          la manière décrite à l'adresse suivante : www.cnil.fr Le refus
          d'installation d'un cookie peut entraîner l'impossibilité d'accéder à
          certains services. L'utilisateur peut toutefois configurer son
          ordinateur de la manière suivante, pour refuser l'installation des
          cookies : Sous Internet Explorer : onglet outil / options internet.
          Cliquez sur Confidentialité et choisissez Bloquer tous les cookies.
          Validez sur Ok. Sous Netscape : onglet édition / préférences. Cliquez
          sur Avancées et choisissez Désactiver les cookies. Validez sur Ok.
        </p>
      </section>
      {/* Six */}
      <section className="mentions_section">
        <h4 className="title_mentions_legale">
          PROTECTION DES BIENS ET DES PERSONNES - GESTION DES DONNÉES
          PERSONNELLES
        </h4>
        <p>
          Utilisateur : Internaute se connectant, utilisant le site susnommé :{" "}
          <a
            className="link_mention_legale"
            href="http://www.lacoopboissy.fr"
            target="_blank"
            rel="noreferrer"
          >
            www.lacoopboissy.fr
          </a>{" "}
          En France, les données personnelles sont notamment protégées par la
          loi n° 78-87 du 6 janvier 1978, la loi n° 2004-801 du 6 août 2004,
          l'article L. 226-13 du Code pénal et la Directive Européenne du 24
          octobre 1995.
        </p>
        <p>
          Sur le site{" "}
          <a
            className="link_mention_legale"
            href="http://www.lacoopboissy.fr"
            target="_blank"
            rel="noreferrer"
          >
            www.lacoopboissy.fr
          </a>
          , le proprietaire du site ne collecte des informations personnelles
          relatives à l'utilisateur que pour le besoin de certains services
          proposés par le site{" "}
          <a
            className="link_mention_legale"
            href="http://www.lacoopboissy.fr"
            target="_blank"
            rel="noreferrer"
          >
            www.lacoopboissy.fr
          </a>
          . L'utilisateur fournit ces informations en toute connaissance de
          cause, notamment lorsqu'il procède par lui-même à leur saisie. Il est
          alors précisé à l'utilisateur du site{" "}
          <a
            className="link_mention_legale"
            href="http://www.lacoopboissy.fr"
            target="_blank"
            rel="noreferrer"
          >
            www.lacoopboissy.fr
          </a>{" "}
          l'obligation ou non de fournir ces informations. Conformément aux
          dispositions des articles 38 et suivants de la loi 78-17 du 6 janvier
          1978 relative à l'informatique, aux fichiers et aux libertés, tout
          utilisateur dispose d'un droit d'accès, de rectification, de
          suppression et d'opposition aux données personnelles le concernant.
          Pour l'exercer, adressez votre demande à{" "}
          <a
            className="link_mention_legale"
            href="http://www.lacoopboissy.fr"
            target="_blank"
            rel="noreferrer"
          >
            www.lacoopboissy.fr
          </a>{" "}
          par email : email du webmaster ou en effectuant sa demande écrite et
          signée, accompagnée d'une copie du titre d'identité avec signature du
          titulaire de la pièce, en précisant l'adresse à laquelle la réponse
          doit être envoyée.
        </p>
        {/* END */}
        <p>
          Aucune information personnelle de l'utilisateur du site{" "}
          <a
            className="link_mention_legale"
            href="http://www.lacoopboissy.fr"
            target="_blank"
            rel="noreferrer"
          >
            www.lacoopboissy.fr
          </a>{" "}
          n'est publiée à l'insu de l'utilisateur, échangée, transférée, cédée
          ou vendue sur un support quelconque à des tiers. Seule l'hypothèse du
          rachat du site{" "}
          <a
            className="link_mention_legale"
            href="http://www.lacoopboissy.fr"
            target="_blank"
            rel="noreferrer"
          >
            www.lacoopboissy.fr
          </a>{" "}
          à le proprietaire du site et de ses droits permettrait la transmission
          des dites informations à l'éventuel acquéreur qui serait à son tour
          tenu de la même obligation de conservation et de modification des
          données vis à vis de l'utilisateur du site{" "}
          <a
            className="link_mention_legale"
            href="http://www.lacoopboissy.fr"
            target="_blank"
            rel="noreferrer"
          >
            www.lacoopboissy.fr
          </a>
          .
        </p>

        <p>
          Le site{" "}
          <a
            className="link_mention_legale"
            href="http://www.lacoopboissy.fr"
            target="_blank"
            rel="noreferrer"
          >
            www.lacoopboissy.fr
          </a>{" "}
          est en conformité avec le RGPD voir notre politique RGPD{" "}
          <a className="link_mention_legale" href="rgpd">
            rgpd
          </a>
          .
        </p>

        <p>
          Les bases de données sont protégées par les dispositions de la loi du
          1er juillet 1998 transposant la directive 96/9 du 11 mars 1996
          relative à la protection juridique des bases de données.
        </p>
      </section>
    </main>
  );
}

export default Legal;
