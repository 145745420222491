import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { getData } from "../../utils/config";

function Drink() {
  const [beer, setBeer] = useState([]);
  const [soft, setSoft] = useState([]);
  const [wine, setWine] = useState([]);
  const [error, setError] = useState(false);
  useEffect(() => {
    getData("wines")
      .then((res) => {
        setWine(res.data.data);
      })
      .catch((err) => {
        setError(true);
      });
    getData("beers")
      .then((res) => {
        setBeer(res.data.data);
      })
      .catch((err) => {
        setError(true);
      });
    getData("softs")
      .then((res) => {
        setSoft(res.data.data);
      })
      .catch((err) => {
        setError(true);
      });
  }, []);
  return (
    <article className="menu_content">
      {error ? (
        "Oups, une erreur est survenue"
      ) : (
        <>
          {wine.length > 0 &&
            wine.map((tart, index) => {
              return (
                <div key={index}>
                  <div className="container_name">
                    <span>{tart.attributes.category}</span>
                  </div>
                  {tart.attributes.item.length === 0 ? (
                    <p className="error">Nos tartines arrivent bientôt</p>
                  ) : (
                    <ul className="content_nav">
                      {tart.attributes.item.map((produit) => {
                        return (
                          <li className="item_content" key={produit.id}>
                            <div className="flex_item">
                              <span>{produit.name} </span>
                              <span>{produit.domain}</span>
                              <span>{produit.brand}</span>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>
              );
            })}
          {beer.length > 0 &&
            beer.map((bubble, index) => {
              return (
                <div key={index}>
                  <div className="container_name">
                    <span>{bubble.attributes.category}</span>
                  </div>
                  {bubble.attributes.item.length === 0 ? (
                    <p className="error">Nos cocktails arrivent bientôt</p>
                  ) : (
                    <ul className="content_nav">
                      {bubble.attributes.item.map((produit) => {
                        return (
                          <li className="item_content" key={produit.id}>
                            {produit.produit}
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>
              );
            })}
          {soft.length > 0 && (
            <>
              <div className="container_name">
                <span>{soft[0].attributes.category}</span>
              </div>
              <ul className="content_nav">
                {soft[0]?.attributes.item.map((product, index) => {
                  return (
                    <li className="item_content" key={index}>
                      {product.item}
                    </li>
                  );
                })}
                <li className="item_content">
                  <div className="container_flavour">
                    <span>{soft[0].attributes.category_flavour} :</span>
                    <ul className="content_flavour">
                      {soft[0]?.attributes.flavour.map((product, index) => {
                        return (
                          <li className="item_content " key={index}>
                            - {product.produit}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </li>
              </ul>
            </>
          )}
        </>
      )}
    </article>
  );
}

export default Drink;
