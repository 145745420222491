import React from "react";
import Markdown from "react-markdown";
import ImageBlock from "./ImageBlock";
function More({ more, isHide }) {
  return (
    <>
      {more.map((data, index) => {
        return (
          <React.Fragment key={index}>
            {data?.year && <p className="year_block more_year">{`${data?.year} :`} </p>}{" "}
            <ImageBlock img={data?.image} />
            <div className="block_content">
              {data?.content && <Markdown>{data.content}</Markdown>}
            </div>
          </React.Fragment>
        );
      })}
    </>
  );
}

export default More;
