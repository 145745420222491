import React, { useState } from "react";
import ImageBlock from "./ImageBlock";
import More from "./More";

import Markdown from "react-markdown";
function MainBlock({ year, caption, img, content, more, refData }) {
  const [isHide, setIsHide] = useState(true);
  return (
    <div className="test">
      <div className="block_archive">
        {/* Année direct ici */}
        <p className="year_block">{year && year}</p>
        {/* Caption Gras ici */}
        <p className="caption_block">{caption && caption}</p>
        {/* Image Maybe more than One component */}
        <ImageBlock img={img} />
        {/* Texte Makdown reactmarkdown ici  */}
        {content && (
          <div className="block_content">
            <Markdown>{content}</Markdown>
          </div>
        )}
        {/* More = Optionnal / component */}
        {more.length > 0 && (
          <>
            {isHide ? (
              <p className="see_more" onClick={() => setIsHide(!isHide)}>
                Voir plus
              </p>
            ) : (
              <p className="see_more" onClick={() => setIsHide(!isHide)}>
                Voir moins
              </p>
            )}

            <div className={`${isHide ? "hide_content" : "show_content"}`}>
              <More more={more} isHide={isHide} />
            </div>
          </>
        )}
      </div>

      <span className={`frise ${refData}`}>
        <p className="year_absolute">{year && year}</p>
        <p className="caption_absolute">{caption && caption}</p>
      </span>
    </div>
  );
}

export default MainBlock;
