import axios from "axios";

export const getData = async (data) => {
  return await axios
    .get(`https://www.strapiadmin.lacoopboissy.fr/api/${data}?populate=*`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};
