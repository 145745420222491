import React from "react";
import Carousel from "../../Swiper/Carousel";
import { SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/scss/navigation";
function Image({ img }) {
  return (
    <div className="archive_block_image">
      {img?.data?.length > 1 ? (
        <Carousel>
          {img?.data?.map((image) => {
            return (
              <SwiperSlide key={image.id}>
                <div className="image_block">
                  <img
                    src={`https://www.strapiadmin.lacoopboissy.fr${image?.attributes?.url}`}
                    alt={image?.alternativeText}
                    className="image_archive"
                  />
                </div>
              </SwiperSlide>
            );
          })}
        </Carousel>
      ) : (
        <>
          {img?.data?.map((image) => {
            return (
              <div className="image_block" key={image.id}>
                <img
                  src={`https://www.strapiadmin.lacoopboissy.fr${image?.attributes?.url}`}
                  alt={image?.alternativeText}
                  className="image_archive"
                />
              </div>
            );
          })}
        </>
      )}
    </div>
  );
}

export default Image;
