import React, { useRef } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import Head from "../../layout/Head";
function Contact() {
  const contact = useRef();
  function focus() {
    contact.current.scrollIntoView({ behavior: "smooth" });
  }
  return (
    <main className="container_concept ">
      <Head
        title="Contact"
        content="Numéro: 06 58 08 04 09, Email: lacoopboissy@gmail.com, Adresse: 2 Rue du Centre, 77169 Boissy-le-Châtel"
      />
      <div className="contact_opac">
        <h1 className="concept_title">Contact</h1>
        <div className="opac"></div>
        <div className="scroll" onClick={() => focus()}>
          <MdKeyboardArrowDown className="emoji" />
        </div>
      </div>
      <section className="container_us_contact" ref={contact}>
        <h2>Retrouvez-nous</h2>
        <section className="us">
          <div className="container_reseau">
            <div className="reseau">
              <span>Numéro : </span>
              <span>06 58 08 04 09</span>
            </div>
            <div className="reseau">
              <span>Email : </span>
              <span>lacoopboissy@gmail.com </span>
            </div>
            <div className="reseau">
              <span>Instagram : </span>
              <a
                href="https://instagram.com/barlacoopboissy?igshid=YmMyMTA2M2Y="
                target="_blank"
                rel="noreferrer"
              >
                Voir notre Instagram
              </a>
            </div>
            <div className="reseau">
              <span>Facebook : </span>
              <a
                href="https://www.facebook.com/profile.php?id=100087671659726"
                target="_blank"
                rel="noreferrer"
              >
                Voir notre Facebook
              </a>
            </div>
          </div>
          <div className="container_carousel">
            <iframe
              title="myMap"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2626.950373271058!2d3.1348672159138764!3d48.82100831129173!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e8b79ef501d6ab%3A0x3bbdcbb921829bcf!2s2%20Rue%20du%20Ctre%2C%2077169%20Boissy-le-Ch%C3%A2tel!5e0!3m2!1sfr!2sfr!4v1664961565885!5m2!1sfr!2sfr"
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              className="map"
            ></iframe>
          </div>
        </section>
      </section>
      {/* Remplacer texte reseau par Icone  */}
      {/* Site web  */}
    </main>
  );
}

export default Contact;
