import React from "react";
import useFetch from "../History/hook/useFetch";

function Activity() {
  const { data, loading, error } = useFetch(
    "https://www.strapiadmin.lacoopboissy.fr/api/activities?populate=*"
  );
  console.log(data);
  return (
    <main className="container_producteur">
      {loading && (
        <p className="archive_loading">
          Le contenu est en cours de chargement...
        </p>
      )}
      {error && <p className="archive_loading">{error}</p>}
      {data && (
        <section className="section_producteur">
          <h1>Retrouvez ici nos activités en semaine.</h1>

          {data?.map((activity, index) => {
            return (
              <div className="container_activity">
                <div className="container_img_activity">
                  {/* Image */}
                  {console.log(
                    activity?.attributes?.image?.data?.attributes?.url
                  )}
                  <img
                    src={`https://www.strapiadmin.lacoopboissy.fr${activity?.attributes?.image?.data?.attributes?.url}`}
                    alt={
                      activity?.attributes?.image?.data?.attributes
                        ?.alternativeText
                    }
                  />
                </div>

                <div className="container_content_activity">
                  <p>{activity?.attributes?.title}</p>
                  <p>{activity?.attributes?.description}</p>
                </div>
              </div>
            );
          })}
        </section>
      )}
    </main>
  );
}

export default Activity;
