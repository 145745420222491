import React from "react";

function MonConcept() {
  const data = [
    {
      title: "L'Équipe",
      subTitle: "Félicien, Milca et Yoann",
      first:
        "Tous les trois, enfants d'une Brie riche et nourricière, nos néo-buccéens veulent faire revivre un lieu emblématique du village de Boissy-Le-Châtel.",
      second:
        " Félicien et Yoann, barmans de formation, vous accueillent avec bonne humeur en salle et vous font découvrir tout ce que la Seine-et-Marne a de bon et de gourmand. Milca, infirmière de métier, s'occupera de toute la partie événementielle et culturelle, avec l'énergie qui la caractérise. ",
      content:
        " Au programme, quelques pépites à voir et à entendre, dans un lieu atypique, entièrement créé avec une grande majorité de matériel de récupération et de brocante.",
      url: require("../../asset/fun2.JPG"),
    },
    {
      url: require("../../asset/felicienAubar.jpg"),
    },
  ];
  return (
    <div className="us_content">
      <h3>{data[0].title} </h3>
      <div className="us_text">
        <p>{data[0].subTitle}</p>
        <p>{data[0].first}</p>
        <p>{data[0].second}</p>
        <p>{data[0].content}</p>
      </div>
    </div>
  );
}

export default MonConcept;
