import React from "react";

function Equipe() {
  const data = [
    {
      title: "Notre Concept",
      subTitle: "Nos valeurs :",
      contentConcept: [
        { item: "Nous oeuvrons pour ramener la vie dans nos villages" },
        {
          item: "Nous croyons à la richesse gastronomique et conviviale de notre région",
        },
        {
          item: "Nous privilégions les produits naturels et le circuit court",
        },
        {
          item: "Nous voulons profiter et faire profiter de la culture à la campagne",
        },
        {
          item: "Nous proposons concerts, spectacles, conteurs, expos, découvertes d’ateliers...",
        },
      ],
      url: require("../../asset/felicienAubar.jpg"),
    },
  ];
  return (
    <div className="us_content">
      <h3>{data[0].title} </h3>
      <div className="us_text">
        <span>{data[0].subTitle}</span>
        <ul>
          {data[0].contentConcept.map((text, index) => {
            return (
              <div className="container_item_round" key={index}>
                <div></div>
                <li>{text.item}</li>
              </div>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

export default Equipe;
