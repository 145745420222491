import React from "react";
import { useNavigate } from "react-router-dom";

function NotFound() {
  const navigate = useNavigate();

  return (
    <section className="notfound_container">
      <div className="notfound_flex">
        <p> Oups ! vous semblez êtes perdu</p>
        <p>
          Aucune inquiétude, si tous les chemins mènent à Rome, il suffit de
          cliquer sur le bouton ci-dessous pour retourner à l'accueil.
        </p>
        <button
          className="notfound_button"
          onClick={() => {
            navigate("/");
          }}
        >
          Je retourne à l'accueil
        </button>
      </div>
    </section>
  );
}

export default NotFound;
