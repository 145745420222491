import React from "react";
import DHeader from "./Desktop/DHeader";
import MHeader from "./Mobile/MHeader";

function Header() {
  return (
    <header>
      <MHeader />
      <DHeader />
    </header>
  );
}

export default Header;
