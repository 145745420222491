import React from "react";
import { NavLink } from "react-router-dom";
import { AiFillFacebook } from "react-icons/ai";
import { AiFillInstagram } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

function DHeader() {
  const navigate = useNavigate();
  const [select, setSelect] = useState("home");
  return (
    <div className="container_desk_header">
      <div className="container_desk_flex">
        {/* Logo */}
        <div
          className="container_desk_logo"
          onClick={() => {
            navigate("/");
          }}
        >
          <p>LA</p>
          <p>COOP</p>
        </div>
        {/* NAvigation */}
        <nav className="nav_desk">
          <ul className="header_list_desk">
            <li>
              <NavLink className="desk_items" to="/">
                <span
                  className={`${select === "home" ? "item_desk_select" : ""}`}
                  onClick={() => {
                    setSelect("home");
                  }}
                >
                  Accueil
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink className="desk_items" to="concept">
                <span
                  className={`${
                    select === "Concept" ? "item_desk_select" : ""
                  }`}
                  onClick={() => {
                    setSelect("Concept");
                  }}
                >
                  Concept
                </span>
              </NavLink>
            </li>
            {/* <li>
              <NavLink className="desk_items" to="carte">
                <span
                  className={`${select === "Carte" ? "item_desk_select" : ""}`}
                  onClick={() => {
                    setSelect("Carte");
                  }}
                >
                  Carte
                </span>
              </NavLink>
            </li> */}
            <li>
              <NavLink className="desk_items" to="galerie">
                <span
                  className={`${
                    select === "Galerie" ? "item_desk_select" : ""
                  }`}
                  onClick={() => {
                    setSelect("Galerie");
                  }}
                >
                  Galerie
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink className="desk_items" to="event">
                <span
                  className={`${
                    select === "Évenements" ? "item_desk_select" : ""
                  }`}
                  onClick={() => {
                    setSelect("Évenements");
                  }}
                >
                  Évenements
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink className="desk_items" to="activite-associations">
                <span
                  className={`${
                    select === "Activités" ? "item_desk_select" : ""
                  }`}
                  onClick={() => {
                    setSelect("Activités");
                  }}
                >
                  Activités et
                </span>
                <span
                  className={`${
                    select === "Activités" ? "item_desk_select" : ""
                  }`}
                  onClick={() => {
                    setSelect("Activités");
                  }}
                >
                  Associations
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink className="desk_items" to="producteurs">
                <span
                  className={`${
                    select === "Producteurs" ? "item_desk_select" : ""
                  }`}
                  onClick={() => {
                    setSelect("Producteurs");
                  }}
                >
                  Producteurs
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink className="desk_items" to="notre-histoire">
                <span
                  className={`${
                    select === "Histoire" ? "item_desk_select" : ""
                  }`}
                  onClick={() => {
                    setSelect("Histoire");
                  }}
                >
                  Histoire
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink className="desk_items" to="contact">
                <span
                  className={`${
                    select === "Contact" ? "item_desk_select" : ""
                  }`}
                  onClick={() => {
                    setSelect("Contact");
                  }}
                >
                  Contact
                </span>
              </NavLink>
            </li>
          </ul>
        </nav>
        {/* Reseaux */}
        <div className="desk_reseau">
          <a
            href="https://www.facebook.com/profile.php?id=100087671659726"
            target="blank"
            className="desk-link"
          >
            <AiFillFacebook className="emoji" />
          </a>
          <a
            href="https://instagram.com/barlacoopboissy?igshid=YmMyMTA2M2Y="
            target="blank"
            className="desk-link"
          >
            <AiFillInstagram className="emoji" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default DHeader;
