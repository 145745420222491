import React from "react";
import Timeline from "../TimeLine/Timeline";
import MainBlock from "./components/MainBlock";
import useFetch from "./hook/useFetch";
import Head from "../../layout/Head";

function History() {
  const { data, loading, error } = useFetch(
    "https://www.strapiadmin.lacoopboissy.fr/api/histories?populate[content_history][populate][main][populate]=*&populate[content_history][populate][More][populate]=*"
  );
  return (
    <main className="container_archive">
      <Head
        title="Histoire"
        content="Découvrez l'évolution de LaCOOP des années 1700 à aujourd'hui."
      />
      <section className="archive-content">
        <h1>notre histoire</h1>
        {loading && (
          <p className="archive_loading">
            Le contenu est en cours de chargement...
          </p>
        )}
        {error && <p className="archive_loading">{error}</p>}
        {data && (
          <div className="grid_container">
            <Timeline />
            <div className="archive">
              {data?.map((item) => {
                return (
                  <MainBlock
                    key={item?.id}
                    year={item?.attributes?.content_history?.main?.year}
                    caption={item?.attributes?.content_history?.main?.caption}
                    img={item?.attributes?.content_history?.main?.image}
                    content={item?.attributes?.content_history?.main?.content}
                    more={item?.attributes?.content_history?.More}
                    refData={item?.attributes?.content_history?.main?.ref}
                  />
                );
              })}
            </div>
          </div>
        )}
      </section>
    </main>
  );
}

export default History;
