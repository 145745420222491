import React from "react";
import useFetch from "../History/hook/useFetch";
import { AiFillFacebook } from "react-icons/ai";
import { AiFillInstagram } from "react-icons/ai";
import { BiLinkExternal } from "react-icons/bi";

function Producteur() {
  const { data, loading, error } = useFetch(
    "https://www.strapiadmin.lacoopboissy.fr/api/producteurs?populate=*"
  );
  return (
    <main className="container_producteur">
      {loading && (
        <p className="archive_loading">
          Le contenu est en cours de chargement...
        </p>
      )}
      {error && <p className="archive_loading">{error}</p>}
      {data && (
        <section className="section_producteur">
          {data?.map((produ, index) => {
            console.log(produ);
            return (
              <div className="container_producteur_item">
                <p>{produ.attributes.name}</p>
                <ul className="list_producteur">
                  {produ.attributes.website && (
                    <li className="item_list_producteur">
                      <BiLinkExternal className="emoji_producteur" />
                      <a
                        href={produ.attributes.website}
                        className="link_list_producteur"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Voir leur site
                      </a>
                    </li>
                  )}
                  {produ.attributes.facebook && (
                    <li className="item_list_producteur">
                      <AiFillFacebook className="emoji_producteur" />
                      <a
                        href={produ.attributes.facebook}
                        className="link_list_producteur"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Voir leur facebook
                      </a>
                    </li>
                  )}{" "}
                  {produ.attributes.instagram && (
                    <li className="item_list_producteur">
                      <AiFillInstagram className="emoji_producteur" />
                      <a
                        href={produ.attributes.instagram}
                        className="link_list_producteur"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {" "}
                        Voir leur instagram
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            );
          })}
        </section>
      )}
    </main>
  );
}

export default Producteur;
