import React from "react";
import { Swiper } from "swiper/react";
import "swiper/scss";
import "swiper/scss/navigation";

import { Navigation } from "swiper";

function Carousel({ children, value }) {
  return (
    <Swiper
      navigation={true}
      modules={[Navigation]}
      className="mySwiper"
      onSlideChange={(swiper) => {
        {
          value && value(swiper.realIndex);
        }
      }}
    >
      {children}
    </Swiper>
  );
}
export default Carousel;
