import React from "react";
import axios from "axios";
import { useEffect } from "react";
import { AiFillFacebook } from "react-icons/ai";
import { AiFillInstagram } from "react-icons/ai";
import { useState } from "react";
import moment from "moment";
import Head from "../../layout/Head";

function Event() {
  const [myEvent, setMyEvent] = useState([]);
  const [select, setSelect] = useState("soon");

  async function getEvent(arg) {
    const past = await axios
      .get(
        `https://www.strapiadmin.lacoopboissy.fr/api/events?populate=*&filters[$and][0][categorie][$contains]=${arg}`
      )
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {});
    return setMyEvent(past);
  }

  useEffect(() => {
    getEvent(select);
  }, [select]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <main className="container_event ">
      <Head
        title="Événements culturels "
        content="Retrouvez tous nos événements musicaux, sportifs et ateliers à venir ou passé. Les artistes conviés sont pour la majorité habitant de la Seine-et-Marne."
      />
      <div className="container_nav">
        <ul className="menu_nav">
          <li
            onClick={() => {
              getEvent("past");
              setSelect("past");
            }}
            className={select === "past" ? " activeBut" : null}
          >
            Passé
          </li>
          <li
            onClick={() => {
              getEvent("current");
              setSelect("current");
            }}
            className={select === "current" ? " activeBut" : null}
          >
            Aujourd'hui
          </li>
          <li
            onClick={() => {
              getEvent("soon");
              setSelect("soon");
            }}
            className={select === "soon" ? " activeBut" : null}
          >
            A venir
          </li>
        </ul>
      </div>
      <section className="menu_content">
        {myEvent.length === 0 ? (
          <p className="error">Aucun événement de prévu</p>
        ) : (
          <ul className="content_event">
            {myEvent &&
              myEvent?.map((event, index) => {
                return (
                  <li className="item_event" key={index}>
                    <div className="event_img">
                      <img
                        src={`https://www.strapiadmin.lacoopboissy.fr/${event?.attributes.photo.data.attributes.url}`}
                        alt=""
                      />
                      <span>{event.attributes.title}</span>
                      <div className="event_desc">
                        <p>{event.attributes.description} </p>
                      </div>
                    </div>
                    <div className="event_detail">
                      <div>
                        <span>{event.attributes.date.day}</span>
                        <span>{event.attributes.date.number}</span>
                        <span>{event.attributes.date.month}</span>
                      </div>
                      <div>
                        <span>{event.attributes.title}</span>
                        <span>
                          {moment(event.attributes.hour, "H:mm").format("H:m")}
                        </span>
                        <span>{event.attributes.price}€</span>
                        <div className="reseau">
                          {event.attributes.facebook !== null ? (
                            <a
                              href={`https://www.facebook.com/${event.attributes.facebook}`}
                              target="blank"
                              className="emoji_link"
                            >
                              <AiFillFacebook className="emoji_event" />
                            </a>
                          ) : null}
                          {event.attributes.instagram !== null ? (
                            <a
                              href={`https://instagram.com/${event.attributes.instagram}`}
                              target="blank"
                              className="emoji_link"
                            >
                              <AiFillInstagram className="emoji_event" />
                            </a>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })}
          </ul>
        )}
      </section>
    </main>
  );
}

export default Event;
